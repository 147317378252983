body {
  height: 100%;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: visible;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  z-index: 0;

  .App {
    height: 100vh;
    header {
      img {
        max-width: 45px;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
        border-radius: 25px;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      background-image: linear-gradient(#000, hsla(0, 0%, 100%, 0));
      position: fixed;
      z-index: 3;

      font-family: Yantramanav, sans-serif;
      font-weight: 500;
      width: 100%;

      .left-elements {
        display: flex;
        align-items: center;
        * {
          margin-right: 15px;
        }
      }

      .right-elements {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 10px; 
        margin-right: 30px; 
        a {
          img {
            margin-left: 20px;
            filter: brightness(0) invert(1);
            &:hover {
              filter: invert(68%) sepia(61%) saturate(251%) hue-rotate(305deg) brightness(110%) contrast(105%);
            }
          }
        }
      }

      font-size: calc(10px + 1vmin);
      color: white;
      padding: 0px 10px;
    }
    video {
      width: 100vw;
    }

    .landingBoxesContainer {
      width: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      font-size: 0px;
      padding-bottom: 20px;
      background: white;
    }

    .landingBox {
      color: black;
      background: white;
      max-width: 150px;
      max-height: 150px;
      min-width: 100px;
      min-height: 100px;
      padding: 0;
      border: none;
      position: relative;
      font-size: 0px;
      margin: 10px;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.285),
        0px 10px 10px rgba(0, 0, 0, 0.394);
      border-radius: 15px;
    }

    .landingBoxInner {
      background: white;
      width: 150px;
      height: 150px;
      padding: 0;
      border: none;
      position: relative;
      font-size: 0px;
      width: 100%;
      background-size: cover;
      border-radius: 15px;

      .landingBoxExternalLink {
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        color: white;
        text-decoration: none;
        list-style-type: none;
        height: 76px;

        &:nth-child(1):not(.soloLink) li {
          border-radius: 15px 15px 0px 0px;
        }

        li {
          width: 150px;
          position: relative;
          background-size: 200% 100%;
          background-position: right bottom;
          transition: all 0.25s ease-out;

          &:hover {
            background-position: left bottom;
          }
          &:nth-child(1) li {
            border-radius: 15px 15px 0px 0px;
          }

          div {
            position: absolute;
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 30px;
          }
        }
      }

      .landingBoxExternalLink2 {
        &:nth-child(2) li {
          border-radius: 0px 0px 15px 15px;
        }
        li {
          height: 76px;
        }
      }

      .landingBoxExternalLink3 {
        &:nth-child(3) li {
          border-radius: 0px 0px 15px 15px;
        }
        li {
          height: 50px;
          div {
            top: 15px;
          }
        }
      }
      .soloLink {
        height: 150px;
        width: 150px;
        display: block;
        li {
          height: 150px;
          width: 150px;
          border-radius: 15px;
          div {
            top: 65px;
          }
        }
      }
    }

    .landingBoxImage {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .landingBoxText {
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      font-size: 20px;
      position: absolute;
      bottom: 0px;
      color: white;
      width: 100%;
      margin: 0px;
    }
  }
}

#projectLinks {
  padding: 0px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  li {
    text-align: center;
    display: inline-block;
    list-style-type: none;
    margin: 5px 10px 0px 0px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    background: rgb(35, 35, 35);
    padding: 4px 10px;
    border: 3px solid transparent;
    border-radius: 10px;
    border-color: white;
  }
}

// CSS for the Project Box Icons

.fontIcon {
  padding: 3px 5px 3px 5px;
  color: white;
  transition: color 0.5s;
}

.externalLinks {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: white;
  text-decoration: none;
}

.Bard:hover {
  background: #b69137 !important;
}

.BardPill {
  background: linear-gradient(to right, #9d781e 50%, #d0ab51 50%);
}

.BardDark,
.BardBar::-webkit-scrollbar-thumb {
  background-color: #b69137 !important;
}

.BardDarkOpacity {
  background-color: rgba(182, 146, 55, 0.95) !important;
}

.BardNavItem:hover {
  color: #b69137 !important;
}

button:focus {
  outline: 0;
}

.defaultBar::-webkit-scrollbar-thumb {
  background: black !important;
}

.Rapture:hover {
  background: #005c4a !important;
}

.RapturePill {
  background: linear-gradient(to right, #005c4a 50%, #218f7d 50%);
}

.RaptureDark,
.RaptureBar::-webkit-scrollbar-thumb {
  background-color: #005c4a !important;
}

.RaptureDarkOpacity {
  background-color: rgba(0, 92, 74, 0.95) !important;
}

.RaptureNavItem:hover {
  color: #005c4a !important;
}

.Poll:hover {
  background: #730055 !important;
}

.PollPill {
  background: linear-gradient(to right, #730055 50%, #a61c88 50%);
}

.PollDark,
.PollBar::-webkit-scrollbar-thumb {
  background-color: #730055 !important;
}

.PollDarkOpacity {
  background-color: rgb(115, 0, 85, 0.95) !important;
}

.PollNavItem:hover {
  color: #730055 !important;
}

.TMBG:hover {
  background: #00050d !important;
}

.TMBGPill {
  background: linear-gradient(to right, #00050d 50%, #171e26 50%);
}

.TMBGDark,
.TMBGBar::-webkit-scrollbar-thumb {
  background-color: #00050d !important;
}

.TMBGDarkOpacity {
  background-color: rgb(0, 5, 13, 0.95) !important;
}

.TMBGNavItem:hover {
  color: #00050d !important;
}

.Penny:hover {
  background: #004559 !important;
}

.PennyPill {
  background: linear-gradient(to right, #004559 50%, #18788c 50%);
}

.PennyDark,
.PennyBar::-webkit-scrollbar-thumb {
  background-color: #004559 !important;
}

.PennyDarkOpacity {
  background-color: rgb(0, 69, 89, 0.95) !important;
}

.PennyNavItem:hover {
  color: #004559 !important;
}

.Twitch:hover {
  background: #310e71 !important;
}

.TwitchPill {
  background: linear-gradient(to right, #310e71 50%, #6441a4 50%);
}

.TwitchDark,
.TwitchBar::-webkit-scrollbar-thumb {
  background-color: #310e71 !important;
}

.TwitchDarkOpacity {
  background-color: rgb(49, 14, 113, 0.95) !important;
}

.TwitchNavItem:hover {
  color: #310e71 !important;
}

.C4:hover {
  background: #cc4c00 !important;
}

.C4Pill {
  background: linear-gradient(to right, #cc4c00 50%, #ff7f11 50%);
}

.C4Dark,
.C4Bar::-webkit-scrollbar-thumb {
  background-color: #cc4c00 !important;
}

.C4DarkOpacity {
  background-color: rgb(204, 76, 0, 0.95) !important;
}

.C4NavItem:hover {
  color: #cc4c00 !important;
}

.Weather:hover {
  background: #004d00 !important;
}

.WeatherPill {
  background: linear-gradient(to right, #004d00 50%, #048027 50%);
}

.WeatherDark,
.WeatherBar::-webkit-scrollbar-thumb {
  background-color: #004d00 !important;
}

.WeatherDarkOpacity {
  background-color: rgb(0, 77, 0, 0.95) !important;
}

.WeatherNavItem:hover {
  color: #004d00 !important;
}

.Quote:hover {
  background: #004d4d !important;
}

.QuotePill {
  background: linear-gradient(to right, #004d4d 50%, #008080 50%);
}

.QuoteDark,
.QuoteBar::-webkit-scrollbar-thumb {
  background-color: #004d4d !important;
}

.QuoteDarkOpacity {
  background-color: rgb(0, 77, 77, 0.95) !important;
}

.QuoteNavItem:hover {
  color: #004d4d !important;
}

.NPC:hover {
  background: #bc0000 !important;
}

.NPCPill {
  background: linear-gradient(to right, #bc0000 50%, #ef281a 50%);
}

.NPCDark,
.NPCBar::-webkit-scrollbar-thumb {
  background-color: #bc0000 !important;
}

.NPCDarkOpacity {
  background-color: rgb(188, 0, 0, 0.95) !important;
}

.NPCNavItem:hover {
  color: #bc0000 !important;
}

.Wiki:hover {
  background: #004983 !important;
}

.WikiPill {
  background: linear-gradient(to right, #004983 50%, #007cb6 50%);
}

.WikiDark,
.WikiBar::-webkit-scrollbar-thumb {
  background-color: #004983 !important;
}

.WikiDarkOpacity {
  background-color: rgb(0, 73, 131, 0.95) !important;
}

.WikiNavItem:hover {
  color: #004983 !important;
}

.Calc:hover {
  background: #532a7c !important;
}

.CalcPill {
  background: linear-gradient(to right, #532a7c 50%, #865daf 50%);
}

.CalcDark,
.CalcBar::-webkit-scrollbar-thumb {
  background-color: #532a7c !important;
}

.CalcDarkOpacity {
  background-color: rgb(83, 42, 124, 0.95) !important;
}

.CalcNavItem:hover {
  color: #532a7c !important;
}

.Clock:hover {
  background: #b60000 !important;
}

.ClockPill {
  background: linear-gradient(to right, #b60000 50%, #e90000 50%);
}

.ClockDark,
.ClockBar::-webkit-scrollbar-thumb {
  background-color: #b60000 !important;
}

.ClockDarkOpacity {
  background-color: rgb(182, 0, 0, 0.95) !important;
}

.ClockNavItem:hover {
  color: #b60000 !important;
}

.hiddenLink {
  opacity: 0;
}

// End of CSS for the Project Box Icons

.customScrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(245, 245, 245, 0);
  margin: 10px;
}

.customScrollBar::-webkit-scrollbar {
  width: 12px;
  background-color: rgba(245, 245, 245, 0);
}

.customScrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  border: 1px solid white;
}

.landingContainer, .landingParallax{
  height: 100%;
}

.landingContainer{
  position: relative; 
}

.landingVideoContainer{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; 
}

.landingVideo{
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0%);
  top: 0px; 
  width: 100%;
  overflow: hidden
}

.landingBottomContainer{
  position: absolute;
  left: 0; 
  bottom: 0;
  width: 100%;
  overflow-x: scroll;
  
}


.landingTitleBody{
  background: rgba(17, 17, 17, 0.9);
  color: white; 
  position: absolute;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px; 
  border-radius: 20px; 
  -webkit-transition: -webkit-transform 2s linear; 
  transition: transform 2s linear;
  color: black;
  background: white; 
}

.coloredTitleBody{
  color: white; 
}

.landingTitle{
  font-size: 60px; 
  font-family: 'Geostar', cursive;
  font-family: 'Staatliches', cursive;
}

.landingSubTitle{
  font-size: 20px; 
  font-family: 'Yantramanav', sans-serif;
}

.landingBoxesContainer{
  width: 100%;
  overflow-x:scroll;
  white-space: nowrap;
  font-size: 0px;
  padding-bottom: 20px;
  background: white; 
}

.landingBox{
  background: white;
  max-width: 150px;
  max-height: 150px;
  min-width: 100px;
  min-height: 100px;
  padding: 0;
  border: none;
  position: relative;
  font-size: 0px;
  margin: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.285), 0px 10px 10px rgba(0, 0, 0, 0.394);
  border-radius: 15px;
}

.landingBoxInner{
  background: white;
  max-width: 150px;
  max-height: 150px;
  min-width: 100px;
  min-height: 100px;
  padding: 0;
  border: none;
  position: relative;
  font-size: 0px;
  width: 100%;
  background-size: cover;
  border-radius: 15px;
}

.landingBoxImage{
  width: 100%;
  padding: 0;
  margin: 0;
}

.landingBoxText{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  bottom: 0px;
  color: white;
  width: 100%;
  margin: 0px;
}

.landingAdditionalInfo{
  padding: 5px;
  border-radius: 15px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: 400px;
  font-size: 16px;
}

.landingAdditionalInfoPills{
  font-size: 14px; 
  font-family: 'Yantramanav', sans-serif;
  color: white; 
}


#projectBackEnd, #projectFrontEnd, #overlayFrontEnd, #overlayBackEnd{
  width: 100%;
}
#projectFrontEnd li, #projectBackEnd li, #overlayFrontEnd li, #overlayBackEnd li  {
  border-radius: 5px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 3px 5px 3px 5px;
  margin: 0px 3px 4px 3px;
}
#projectFrontEnd li, #overlayFrontEnd li {
  background: #C54E30;
  border: 1px solid white; 
  padding: 5px; 
}
#projectBackEnd li, #overlayBackEnd li {
  background: #9952B7;
  border: 1px solid white; 
  padding: 5px; 
}
#projectLinks {
  padding: 0px;
  position: absolute;
 /* left: calc(50% - 418px);
  */
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}
#projectLinks li {
  text-align: center;
  display: inline-block;
  list-style-type: none;
  margin: 5px 10px 0px 0px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
 /* background: #2D304C; */
 background: rgb(35, 35, 35); 
  padding: 4px 10px;
  border: 3px solid transparent;
  border-radius: 10px;
  border-color: white; 
}


.recommendationSectionContainer{
  background: white; 
  padding-bottom: 100px; 
}

.recommendationContainer{
  margin: 30px; 
  padding: 20px;
  border: 2px solid white; 
  border-radius: 15px; 
  font-size: 18px;
  color: white; 
  background: black; 
  font-family: 'Yantramanav', sans-serif;
  /*font-style: italic; */
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.285), 0px 10px 10px rgba(0, 0, 0, 0.394);
}

.recommendationQuoteIcon{
  font-size: 30px;
  display: block;
  text-align: center; 
}

.recommendationFooter{
  display: flex; 
}

.recommendationFooterImage{
  width: 80px;
  border-radius: 50px; 
  object-fit: contain;
  align-self: flex-start;
  margin-right: 20px;
  border: 2px solid white; 
}

.recommendationFooterText{
  flex: 1 1 auto;
  margin-top: 20px; 
}

.recommendatonFooterName{
  font-weight: bold; 
  font-style: normal; 
  text-transform: uppercase; 
  margin: 0px; 
}

.recommendationFooterCompany{
 font-style: italic; 
 margin: 0px;
}